import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/Layout'
import Navbar from '../components/Navbar'

const remark = require(`remark`)
const html = require(`remark-html`)

export const IndexPageTemplate = ({ image, title, entryUrl, raceDate, startTime, location, information, register, sponsor, contact, training }) => (
	<div id="page-wrapper">

		<div className="hero" style={{ backgroundImage: `url(${  !!image.childImageSharp ? image.childImageSharp.fluid.src : image })` }}>
			<div className="overlay"></div>
			<Navbar entryUrl={entryUrl} />
			<div className="container">
				<div className="content">
					<h1>{title}</h1>
				</div>
			</div>
		</div>

		<div className="usps columns is-mobile is-multiline">
			<div className="column is-one-quarter-desktop is-half-mobile">
				<svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="calendar-alt" className="svg-inline--fa fa-calendar-alt fa-w-14" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M0 464c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V192H0v272zm320-196c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12h-40c-6.6 0-12-5.4-12-12v-40zm0 128c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12h-40c-6.6 0-12-5.4-12-12v-40zM192 268c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12h-40c-6.6 0-12-5.4-12-12v-40zm0 128c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12h-40c-6.6 0-12-5.4-12-12v-40zM64 268c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12H76c-6.6 0-12-5.4-12-12v-40zm0 128c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12H76c-6.6 0-12-5.4-12-12v-40zM400 64h-48V16c0-8.8-7.2-16-16-16h-32c-8.8 0-16 7.2-16 16v48H160V16c0-8.8-7.2-16-16-16h-32c-8.8 0-16 7.2-16 16v48H48C21.5 64 0 85.5 0 112v48h448v-48c0-26.5-21.5-48-48-48z"></path></svg>
				<h3>{raceDate}</h3>
			</div>
			<div className="column is-one-quarter-desktop is-half-mobile">
				<svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="clock" className="svg-inline--fa fa-clock fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm0 448c-110.5 0-200-89.5-200-200S145.5 56 256 56s200 89.5 200 200-89.5 200-200 200zm61.8-104.4l-84.9-61.7c-3.1-2.3-4.9-5.9-4.9-9.7V116c0-6.6 5.4-12 12-12h32c6.6 0 12 5.4 12 12v141.7l66.8 48.6c5.4 3.9 6.5 11.4 2.6 16.8L334.6 349c-3.9 5.3-11.4 6.5-16.8 2.6z"></path></svg>
				<h3>{startTime}</h3>
			</div>
			<div className="column is-one-quarter-desktop is-half-mobile">
				<svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="map-marker-alt" className="svg-inline--fa fa-map-marker-alt fa-w-12" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path fill="currentColor" d="M172.268 501.67C26.97 291.031 0 269.413 0 192 0 85.961 85.961 0 192 0s192 85.961 192 192c0 77.413-26.97 99.031-172.268 309.67-9.535 13.774-29.93 13.773-39.464 0zM192 272c44.183 0 80-35.817 80-80s-35.817-80-80-80-80 35.817-80 80 35.817 80 80 80z"></path></svg>
				<h3>{location}</h3>
			</div>
			<div className="column is-one-quarter-desktop is-half-mobile">
				<a href={entryUrl} target="_blank" rel="noopener noreferrer">
					<svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="arrow-alt-circle-right" className="svg-inline--fa fa-arrow-alt-circle-right fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M504 256C504 119 393 8 256 8S8 119 8 256s111 248 248 248 248-111 248-248zm-448 0c0-110.5 89.5-200 200-200s200 89.5 200 200-89.5 200-200 200S56 366.5 56 256zm72 20v-40c0-6.6 5.4-12 12-12h116v-67c0-10.7 12.9-16 20.5-8.5l99 99c4.7 4.7 4.7 12.3 0 17l-99 99c-7.6 7.6-20.5 2.2-20.5-8.5v-67H140c-6.6 0-12-5.4-12-12z"></path></svg>
					<h3>Enter now</h3>
				</a>
			</div>
		</div>



		<div className="container std-content" id="information" >
			<h2>{information.title}</h2>
			<div className="columns">
				<div className="column is-half copy">
					<span dangerouslySetInnerHTML={{ __html: information.general}} />
				</div>
				<div className="column is-half">
					<iframe 
						src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d630.1049314093814!2d-0.16430317074083364!3d50.82338899872051!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x0!2zNTDCsDQ5JzI0LjIiTiAwwrAwOSc0OS41Ilc!5e0!3m2!1sen!2suk!4v1630482402665!5m2!1sen!2suk" 
						width="100%" 
						height="450" 
						style={{border: 0}} 
						allowFullScreen="" 
						loading="lazy"
						title="map of race start"
					></iframe>
					<div className="copy">
						<span dangerouslySetInnerHTML={{ __html: information.travel}} />
					</div>
				</div>
			</div>
		</div>


	  <div className="two-col-block" id="register">
			<div className="columns">
				<div className="column is-half pic">
					<img src={!!register.image.childImageSharp ? register.image.childImageSharp.fluid.src : register.image } alt="Register for the Brighton santa dash" />
				</div>
				<div className="column is-half">
					<div className="copy">
						<div>
							<h2>REGISTER FOR THE SANTA DASH</h2>
							<p>{register.copy}</p>
							<a className="button is-primary" href={entryUrl} target="_blank" rel="noopener noreferrer">Enter now</a>
						</div>
					</div>
				</div>
			</div>
	  </div>

	  <div className="container std-content" id="sponsor" >
			<h2>{sponsor.title}</h2>
			<div className="columns">
				<div className="column is-2 logo-img">
					<img src={!!sponsor.image.childImageSharp ? sponsor.image.childImageSharp.fluid.src : sponsor.image } alt="Rocking horse logo" />
				</div>
				<div className="column">
					<div className="copy">
						<span dangerouslySetInnerHTML={{ __html: sponsor.copy}} />
					</div>
				</div>
			</div>
		</div>

		<div className="two-col-block" id="contact">
			<div className="columns">
				<div className="column is-half">
					<div className="copy">
						<div>
							<h2>{contact.title}</h2>
							<div dangerouslySetInnerHTML={{ __html: contact.copy}} />
							<a className="button is-primary is-facebook" href="https://www.facebook.com/brightonsantadash" target="_blank" rel="noopener noreferrer">Find us on Facebook</a>
						</div>
					</div>
				</div>
				<div className="column is-half pic">
					<img src={!!contact.image.childImageSharp ? contact.image.childImageSharp.fluid.src : contact.image } alt="contact us" />
				</div>
			</div>
	  </div>


	  <div className="container std-content has-text-centered" id="training" >
			<h2>{training.title}</h2>
			<div className="copy"><span dangerouslySetInnerHTML={{ __html: training.copy}} /></div>
			<h3 className="guide-heading">{training.heading}</h3>
			
			<div className="columns copy">
				{training.guides.map((guide, index) =>
					<div className="column is-2" key={`guide-`+index}>
						<div className="card">
							<img src={!!guide.image.childImageSharp ? guide.image.childImageSharp.fluid.src : guide.image } alt={guide.title} />
							<a href={guide.link}>{guide.title}</a>
						</div>
					</div>
				)}

			</div>
		</div>

  </div>
)

const IndexPage = ({ data }) => {
  
	
  const { frontmatter } = data.markdownRemark
  frontmatter.information.general = remark().use(html).processSync(frontmatter.information.general).toString()
  frontmatter.information.travel = remark().use(html).processSync(frontmatter.information.travel).toString()
  frontmatter.sponsor.copy = remark().use(html).processSync(frontmatter.sponsor.copy).toString()
  frontmatter.contact.copy = remark().use(html).processSync(frontmatter.contact.copy).toString()
  frontmatter.training.copy = remark().use(html).processSync(frontmatter.training.copy).toString()

  return (
    <Layout title={frontmatter.title} description="Brighton's most fun running race, join hundreds of other Santas in Brighton this December. Price includes suit!">
      <IndexPageTemplate
	  	title={frontmatter.title}
		entryUrl={frontmatter.entryUrl}
		raceDate={frontmatter.raceDate}
		startTime={frontmatter.startTime}
		location={frontmatter.location}
        image={frontmatter.image}
		information={frontmatter.information}
		register={frontmatter.register}
		sponsor={frontmatter.sponsor}
		contact={frontmatter.contact}
		training={frontmatter.training}
      />
    </Layout>
  )
}

export default IndexPage

export const pageQuery = graphql`
	query IndexPageTemplate {
		markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
			frontmatter {
				title
				entryUrl
				raceDate
				startTime
				location
				image {
					childImageSharp {
						fluid(maxWidth: 2048, quality: 100) {
							...GatsbyImageSharpFluid
						}
					}
				}	
				information {
					title
					general
					travel
				}
				register {
					image {
						childImageSharp {
							fluid(maxWidth: 2048, quality: 100) {
								...GatsbyImageSharpFluid
							}
						}
					}
					copy
				}
				sponsor {
					title
					copy
					image {
						childImageSharp {
							fluid(maxWidth: 2048, quality: 100) {
								...GatsbyImageSharpFluid
							}
						}
					}
				}
				contact {
					title
					copy
					image {
						childImageSharp {
							fluid(maxWidth: 2048, quality: 100) {
								...GatsbyImageSharpFluid
							}
						}
					}
				}
				training {
					title
					image {
						childImageSharp {
							fluid(maxWidth: 2048, quality: 100) {
								...GatsbyImageSharpFluid
							}
						}
					}
					heading
					copy
					guides {
						title
						link
						image {
							childImageSharp {
								fluid(maxWidth: 2048, quality: 100) {
									...GatsbyImageSharpFluid
								}
							}
						}
					}
				}
			}
		}
	}
`
